<template>
  <div class="homepage">
    Welcome to Miakapp 3
  </div>
</template>

<script>
export default {
  name: 'Homepage',
};
</script>
